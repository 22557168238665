import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { format, http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const header = [
  'employee id',
  'title name',
  'name',
  'surname',
  'nickname',
  'email',
  'mobile',
  'address',
  'sub district',
  'district',
  'province',
  'postcode',
]
const URL = `${config.api}/v1/user-admin/account/employee`
let state
export class Employee extends BaseStore {
  constructor() {
    super()
    this.observable({
      doc: {},
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      menu_list: [],
      employee_list: [],
    })
    state = this
  }

  async getDoc(id) {
    const url = `${URL}/${id}/full-detail`
    const resp = await http.get(url)

    const data = resp.body

    runInAction(() => {
      state.doc = data
    })
  }

  async updateCompany(json = {}) {
    const url = `${URL}/detail`
    const resp = await http.put(url, { json })

    return resp.body
  }

  toQuery(query = {}) {
    const {
      index = 1,
      per_page = 20,
      status,
      user_id,
      keyword,
      employee_type,
    } = query

    let q = `index=${index}&per_page=${per_page}`

    if (status) q += `&status=${status}`
    if (user_id) q += `&user_id=${user_id}`
    if (keyword) q += `&keyword=${keyword}`
    if (employee_type) q += `&employee_type=${employee_type}`

    return q
  }

  async getList(query) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${URL}?${q}`
    const resp = await http.get(url)

    const list = resp.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page
    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCountList(query) {
    const q = this.toQuery(query)
    const url = `${URL}/counter?${q}`
    const resp = await http.get(url)

    const { counter } = resp.body

    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async getMenuList() {
    const { menu_list, employee_list } = this.toJS()
    if (menu_list.length > 0) {
      return { menu_list, employee_list }
    }

    const url = `${config.api}/v1/user-admin/account/employee/all`
    const res = await http.get(url)

    const list = res.body || []
    const employeeList = []
    const menus = list.map((it) => {
      const {
        user_id,
        nickname,
        name,
        surname,
        name_th,
        surname_th,
        employee_type,
        img_url,
      } = it
      const username = format.toUsername(it)
      const item = {
        user_id,
        username,
        nickname,
        name,
        surname,
        name_th,
        surname_th,
        employee_type,
        img_url,
      }
      employeeList.push(item)

      return { name: username, value: user_id, tag: item }
    })

    runInAction(() => {
      state.menu_list = menus
      state.employee_list = employeeList
    })

    return { menu_list: menus, employee_list: employeeList }
  }

  async createEmployee(json = {}) {
    const url = `${URL}`
    const res = await http.post(url, { json })

    const data = res.body

    const { page, list } = this.toJS()

    list.unshift(data)
    page.total++

    runInAction(() => {
      state.list = list
      state.page = page
      state.menu_list = []
    })
  }

  async updateEmployee(user_id, json = {}) {
    const url = `${URL}/${user_id}/detail`
    const res = await http.put(url, { json })

    const data = res.body
    runInAction(() => {
      state.doc = data
      state.menu_list = []
    })
  }

  setCompany(data) {
    runInAction(() => {
      state.doc = data
    })
  }

  async resetPassword(user_id) {
    const url = `${URL}/${user_id}/email/password`
    await http.put(url, {})
  }

  async sendInvitation(user_id) {
    const url = `${URL}/${user_id}/email/invitation`
    await http.put(url, {})
  }

  async deleteEmployee(user_id) {
    const url = `${URL}/${user_id}/delete`
    const res = await http.put(url, {})

    const data = res.body
    runInAction(() => {
      state.doc = data
      state.menu_list = []
    })
  }

  async activeEmployee(user_id) {
    const url = `${URL}/${user_id}/active`
    const res = await http.put(url, {})

    const data = res.body
    runInAction(() => {
      state.doc = data
    })
  }

  async resignEmployee(user_id, json) {
    const url = `${URL}/${user_id}/resign`
    const res = await http.put(url, { json })

    const data = res.body
    runInAction(() => {
      state.doc = data
    })
  }

  async updateEmail(user_id, json) {
    const url = `${URL}/${user_id}/email`
    const res = await http.put(url, { json })

    const { email } = res.body
    runInAction(() => {
      state.doc.email = email
    })
  }

  async export(query = {}) {
    const { total = 20 } = query
    const q = this.toQuery({
      ...query,
      index: 1,
      per_page: total,
    })
    const url = `${URL}/export?${q}`
    const res = await http.get(url)

    const list = res.body || []

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const data_list = []
    for (const item of list) {
      data_list.push([
        item.employee_id || '',
        item.title_name || '',
        item.name || '',
        item.surname || '',
        item.nickname || '',
        item.email || '',
        item.mobile || '',
        item.street || '',
        item.tumbon || '',
        item.district || '',
        item.province || '',
        item.postcode || '',
      ])
    }

    data_list.unshift(header)
    const ws = XLSX.utils.aoa_to_sheet(data_list)
    const w = 15
    const width = 25
    ws['!cols'] = [
      { width: w },
      { width: w },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
    ]
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'employee')

    XLSX.writeFile(workbook, `employee-${date}.xlsx`)
  }
}

export default new Employee()
