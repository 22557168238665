import { runInAction } from 'mobx'
import { cloneDeep } from 'lodash'

import { format, http, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/timesheet/category`
const original = {
  status: 'active',
  seq: 1,
  all_employee: 'no',
  category_name: '',
  detail: '',
}

const originalSummary = {
  id: 0,
  minute_total: 0,
  summary_monthly: {
    label_list: [],
    value_list: [],
  },
  summary_activity: {
    label_list: [],
    value_list: [],
  },
  activity_list: [],
  member_list: [],
  monthly_list: [],
}

let state
export class TimesheetCategory extends BaseStore {
  constructor() {
    super()
    this.observable({
      menu_list: [],
      manager_list: [],
      member_list: [],
      activity_list: [],
      list: [],
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
      doc: cloneDeep(original),
      summary: cloneDeep(originalSummary),
    })
    state = this
  }

  resetList() {
    runInAction(() => {
      state.list = []
      state.page = {
        index: 1,
        per_page: 20,
        total: 0,
      }
    })
  }

  reset() {
    runInAction(() => {
      state.doc = cloneDeep(original)
    })
  }

  async getDoc(id) {
    const url = `${Url}/${id}/info`
    const res = await http.get(url)

    const data = res.body

    runInAction(() => {
      state.doc = data
    })
  }

  async getMenuList() {
    const { menu_list } = this.toJS()
    if (menu_list.length > 0) {
      return
    }

    const url = `${Url}?per_page=-1`
    const res = await http.get(url)

    const list = res.body || []
    const menus = list.map((it) => {
      return {
        name: it.category_name,
        value: it.ot_category_id,
        tag: it,
      }
    })

    runInAction(() => {
      state.menu_list = menus
    })
  }

  toQuery(query = {}) {
    const { index = 1, per_page = 20, status, ts_cost_center_id } = query
    let q = `index=${index}&per_page=${per_page}`
    if (status) {
      q += `&status=${status}`
    }

    if (ts_cost_center_id) {
      q += `&ts_cost_center_id=${ts_cost_center_id}`
    }

    return q
  }

  async getList(query = {}) {
    const { index = 1, per_page = 20 } = query
    const q = this.toQuery(query)
    const url = `${Url}?${q}`
    const res = await http.get(url)

    const list = res.body || []

    const { page } = this.toJS()

    page.index = index
    page.per_page = per_page

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async getCounter(query = {}) {
    const q = this.toQuery(query)
    const url = `${Url}/counter?${q}`
    const res = await http.get(url)

    const { counter } = res.body
    runInAction(() => {
      state.page.total = counter || 0
    })
  }

  async create({ ts_cost_center_id } = {}, json = {}) {
    const url = `${Url}/${ts_cost_center_id}`
    const res = await http.post(url, { json })
    const data = res.body

    const { list, page } = this.toJS()

    list.unshift(data)
    page.total = page.total + 1

    runInAction(() => {
      state.list = list
      state.page = page
    })
  }

  async update({ ts_category_id }, json = {}) {
    const url = `${Url}/${ts_category_id}`
    await http.put(url, { json })

    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.ts_category_id === ts_category_id)
    if (i !== -1) {
      list[i] = json

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }

    runInAction(() => {
      state.doc = json
    })
  }

  async delete({ ts_category_id } = {}) {
    const url = `${Url}/${ts_category_id}`
    await http.delete(url)

    const { list, page } = this.toJS()

    const i = list.findIndex((it) => it.ts_category_id === ts_category_id)
    if (i !== -1) {
      list.splice(i, 1)
      page.total = page.total - 1

      runInAction(() => {
        state.list = list
        state.page = page
      })
    }
  }

  async getManagerList({ ts_category_id } = {}) {
    const url = `${Url}/${ts_category_id}/manager`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.manager_list = list
    })
  }

  async getMemberList({ ts_category_id } = {}) {
    const url = `${Url}/${ts_category_id}/member`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.member_list = list
    })
  }

  async getActivityList({ ts_category_id } = {}) {
    const url = `${Url}/${ts_category_id}/activity`
    const res = await http.get(url)

    const list = res.body || []

    runInAction(() => {
      state.activity_list = list
    })
  }

  async getSummaryList({ ts_category_id } = {}, { start_at, finish_at } = {}) {
    let url = `${Url}/${ts_category_id}/summary`
    if (start_at && finish_at) {
      const startTxt = start_at.format('DDMMYY')
      const finishTxt = finish_at.format('DDMMYY')
      url += `?start=${startTxt}&&finish=${finishTxt}`
    }
    const res = await http.get(url)
    const {
      minute_total,
      activity_list,
      summary_activity_list,
      monthly_list,
      member_list,
    } = res.body || {}

    const activityList = activity_list.map((it) => {
      return {
        text: it.activity_name,
        minute_total: it.minute_total,
      }
    })

    const memberList = member_list.map((it) => {
      return {
        text: format.toUsername(it),
        minute_total: it.minute_total,
      }
    })

    const summary_activity = {
      label_list: [],
      value_list: [],
    }

    for (const item of summary_activity_list) {
      const { activity_name, value } = item
      const name = `${activity_name}: ${value}%`
      summary_activity.label_list.push(name)
      summary_activity.value_list.push(value || 0)
    }

    const summary_monthly = {
      label_list: [],
      value_list: [],
    }
    for (const item of monthly_list) {
      summary_monthly.label_list.push(item.text)
      summary_monthly.value_list.push(item.manday || 0)
    }

    runInAction(() => {
      state.summary = {
        id: timer.getUnix(),
        minute_total: minute_total || 0,
        summary_monthly,

        summary_activity,
        activity_list: activityList,
        member_list: memberList,
        monthly_list,
      }
    })
  }

  async updateManagerList({ ts_category_id } = {}, list = []) {
    const url = `${Url}/${ts_category_id}/manager`
    await http.put(url, { json: { list } })

    runInAction(() => {
      state.manager_list = list
    })
  }

  async updateMemberList({ ts_category_id } = {}, list = []) {
    const url = `${Url}/${ts_category_id}/member`
    await http.put(url, { json: { list } })

    runInAction(() => {
      state.member_list = list
    })
  }

  async updateActivityList({ ts_category_id } = {}, list = []) {
    const url = `${Url}/${ts_category_id}/activity`
    await http.put(url, { json: { list } })

    runInAction(() => {
      state.activity_list = list
    })
  }
}

export default new TimesheetCategory()
