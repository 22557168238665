import member from './Member'
import lang from './Lang'
import province from './Province'
import consent from './Consent'
import account from './Account'
import message from './Message'
// common
import company from './Company'
import department from './Department'
import team from './Team'
import holiday from './Holiday'
import dashboard from './Dashboard'
import bank from './Bank'
import menu from './Menu'
// employee
import employee from './Employee'
import employeeGeneral from './Employee/General'
import employeePrivacy from './Employee/Privacy'
import employeeBenefit from './Employee/Benefit'
import employeeImporter from './Employee/Importer'
import employeeConfig from './Employee/Config'
// leave request
import leaveReq from './LeaveReq'
import leaveType from './LeaveReq/Type'
import leaveBalance from './LeaveReq/Balance'
import leaveBalanceReport from './LeaveReq/BalanceReport'
import leaveUserBalance from './LeaveReq/UserBalance'
import leaveImporter from './LeaveReq/Importer'
import leaveConfig from './LeaveReq/Config'
// reimbursement
import reimburse from './Reimburse'
import costCenter from './Reimburse/CostCenter'
import reimburseCategory from './Reimburse/Category'
import reimburseConfig from './Reimburse/Config'
import expenseBalance from './Reimburse/Balance'
import expenseImporter from './Reimburse/Importer'
// document
import docRequest from './Document'
import docEslip from './Document/Eslip'
// attendance
import attendance from './Attendance'
import attendanceConfig from './Attendance/Config'
import office from './Attendance/Office'
import workingTime from './Attendance/WorkingTime'
import attendanceEmployee from './Attendance/Employee'
import attendanceSummary from './Attendance/Summary'
import attendanceMonthly from './Attendance/Monthly'
import attendanceWorkingReport from './Attendance/WorkingReport'
import attendanceImporter from './Attendance/Importer'
// payroll
import payroll from './Payroll'
import payrollBenefit from './Payroll/Benefit'
import payrollReport from './Payroll/Report'
import payrollImporter from './Payroll/Importer'
import payrollConfig from './Payroll/Config'
import payrollUser from './Payroll/User'
import payrollPayslip from './Payroll/Payslip'
import welfare from './Payroll/Welfare'
import welfareUser from './Payroll/WelfareUser'
import payrollIncompleted from './Payroll/Incompleted'
import tvi50 from './Payroll/Tvi50'
// overtime
import overtime from './Overtime'
import overtimeCategory from './Overtime/Category'
import overtimeConfig from './Overtime/Config'
// timesheet
import timesheet from './Timesheet'
import timesheetCostCenter from './Timesheet/CostCenter'
import timesheetCategory from './Timesheet/Category'
import timesheetActivity from './Timesheet/Activity'
import timesheetConfig from './Timesheet/Config'
import timesheetSummary from './Timesheet/Summary'
import timesheetDashboard from './Timesheet/Dashboard'
import timesheetReport from './Timesheet/Report'
// admin
import admin from './Admin'
import adminRole from './Admin/Role'

/* eslint-disable */
export default {
  member,
  lang,
  account,
  province,
  consent,
  company,
  menu,
  message,
  bank,
  employee,
  employeeGeneral,
  employeePrivacy,
  employeeImporter,
  employeeBenefit,
  employeeConfig,
  department,
  team,
  holiday,
  dashboard,

  leaveReq,
  leaveType,
  leaveBalance,
  leaveBalanceReport,
  leaveUserBalance,
  leaveConfig,
  leaveImporter,

  reimburse,
  costCenter,
  reimburseCategory,
  reimburseConfig,
  expenseBalance,
  expenseImporter,

  docRequest,
  docEslip,

  attendance,
  attendanceConfig,
  attendanceEmployee,
  attendanceSummary,
  attendanceWorkingReport,
  attendanceImporter,
  attendanceMonthly,
  office,
  workingTime,

  payroll,
  payrollBenefit,
  payrollReport,
  payrollImporter,
  payrollConfig,
  payrollUser,
  payrollPayslip,
  payrollIncompleted,
  welfare,
  welfareUser,
  tvi50,

  overtime,
  overtimeCategory,
  overtimeConfig,

  timesheet,
  timesheetCostCenter,
  timesheetCategory,
  timesheetActivity,
  timesheetSummary,
  timesheetDashboard,
  timesheetConfig,
  timesheetReport,

  admin,
  adminRole
}
/* eslint-enable */
