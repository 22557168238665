import { runInAction } from 'mobx'
import * as XLSX from 'xlsx'

import { http, format, timer } from 'utils'
import { config } from 'config'

import BaseStore from '../BaseStore'

const Url = `${config.api}/v1/user-admin/timesheet/report`

const getFilterList = (list, filter = {}) => {
  const { user_id, ts_cost_center_id, ts_category_id, ts_activity_id } = filter
  const filterList = []

  if (!user_id && !ts_cost_center_id && !ts_category_id && !ts_activity_id) {
    return list
  }

  for (const item of list) {
    const isUser = user_id ? item.user_id === user_id : true
    const isCostCenter = ts_cost_center_id
      ? item.ts_cost_center_id === ts_cost_center_id
      : true
    const isCategory = ts_category_id
      ? item.ts_category_id === ts_category_id
      : true
    const isActivity = ts_activity_id
      ? item.ts_activity_id === ts_activity_id
      : true

    if (isUser && isCostCenter && isCategory && isActivity) {
      filterList.push(item)
    }
  }

  return filterList
}

let state
export class TimesheetReport extends BaseStore {
  constructor() {
    super()
    this.observable({
      user_menu: [],
      cost_center_menu: [],
      category_menu: [],
      activity_menu: [],
      display_list: [],
      list: [],
      filter: {},
      page: {
        index: 1,
        per_page: 20,
        total: 0,
      },
    })
    state = this
  }

  async getList(json = {}) {
    const index = 1
    const per_page = 10
    const url = `${Url}/group-user`
    const res = await http.post(url, { json })

    const {
      list = [],
      employee_list = [],
      cost_center_list = [],
      category_list = [],
      activity_list = [],
    } = res.body || {}

    const page = {
      index,
      per_page,
      total: list.length,
    }

    const filter = {
      date_range: json.date_range || 'this_month',
      start_date: json.start_date,
      end_date: json.start_date,
    }

    const display_list = list.slice(0, per_page)

    const user_menu = employee_list.map((it) => {
      const { user_id } = it
      return {
        value: user_id,
        name: format.toUsername(it, false),
      }
    })

    const cost_center_menu = cost_center_list.map((it) => {
      const { ts_cost_center_id, cost_center_name } = it
      return {
        value: ts_cost_center_id,
        name: cost_center_name,
      }
    })

    const category_menu = category_list.map((it) => {
      const { ts_category_id, category_name } = it
      return {
        value: ts_category_id,
        name: category_name,
      }
    })

    const activity_menu = activity_list.map((it) => {
      const { ts_activity_id, activity_name } = it
      return {
        value: ts_activity_id,
        name: activity_name,
      }
    })

    runInAction(() => {
      state.list = list
      state.display_list = display_list
      state.page = page
      state.filter = filter
      state.user_menu = user_menu

      state.cost_center_menu = cost_center_menu
      state.category_menu = category_menu
      state.activity_menu = activity_menu
    })
  }

  setPage({ index }) {
    const { page = {}, filter, list = [] } = this.toJS()
    page.index = index

    const filterList = getFilterList(list, filter)

    const iStart = (index - 1) * page.per_page
    const display_list = filterList.slice(iStart, iStart + page.per_page)
    runInAction(() => {
      state.display_list = display_list
      state.page = page
    })
  }

  setFilter(filter = {}) {
    const { page = {}, list = [] } = this.toJS()
    const filterList = getFilterList(list, filter)

    page.index = 1
    page.total = filterList.length
    const display_list = filterList.slice(0, page.per_page)
    runInAction(() => {
      state.display_list = display_list
      state.page = page
      state.filter = filter
    })
  }

  async export() {
    const { filter, list = [] } = this.toJS()
    const filterList = getFilterList(list, filter)

    const today = timer.get()
    const date = today.format('DDMMYY-HHmm')

    const data_list = []
    for (const item of filterList) {
      const { hour, minute } = format.getWorkTime(item.total_minute)
      const day = Math.trunc(hour / 8)
      const hour2 = Math.trunc(hour % 8)

      data_list.push([
        format.toEmployeeName(item),
        item.nickname,
        item.position || '',
        item.employee_type || '',
        item.cost_center_name || '',
        item.category_name || '',
        item.activity_name || '',
        day,
        hour2,
        minute,
      ])
    }

    const header = [
      'name',
      'nickname',
      'position',
      'employee type',
      'cost center',
      'category/feature',
      'activity',
      'days',
      'hours',
      'minutes',
    ]

    data_list.unshift(header)
    const ws = XLSX.utils.aoa_to_sheet(data_list)
    const width = 25
    const w = 12
    ws['!cols'] = [
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width },
      { width: w }, // days
      { width: w },
      { width: w },
    ]
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, ws, 'timesheet')

    XLSX.writeFile(workbook, `timesheet-${date}.xlsx`)
  }
}

export default new TimesheetReport()
